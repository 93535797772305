import React from "react";

import { Container, Row, Col } from "reusecore/Layout";
import SectionTitle from "reusecore/SectionTitle";

import Icon1 from "assets/images/app/features/01.svg";
import Icon2 from "assets/images/app/features/02.svg";
import Icon3 from "assets/images/app/features/03.svg";
import Icon4 from "assets/images/app/features/04.svg";
import Icon5 from "assets/images/app/features/05.svg";
import Icon6 from "assets/images/app/features/06.svg";

import FeturesSectionWrapper from "./fetures.style";

const Features = () => {
  return (
    <FeturesSectionWrapper id="features">
      <Container fullWidthSM>
        <SectionTitle UniWidth="65%">
          <h4>Features</h4>
          <h2>
            What <span>Requirements Pro </span> can do for you.
          </h2>
        </SectionTitle>
        <Row>
          <Col xs={12} sm={6} md={4}>
            <div className="fetures-block v1">
              <div className="fetures-icon-block">
                <img src={Icon1} alt="img" />
              </div>
              <h3>Increase Collaboration</h3>
              <p>
              Interactive requirements prioritisation session to reach consensus at a blazing pace.
              </p>
            </div>
          </Col>
          <Col xs={12} sm={6} md={4}>
            <div className="fetures-block v2">
              <div className="fetures-icon-block">
                <img src={Icon2} alt="img" />
              </div>
              <h3>Leverage Data</h3>
              <p>
                Real-time analytics on requirements enables increased business value.
              </p>
            </div>
          </Col>
          <Col xs={12} sm={6} md={4}>
            <div className="fetures-block v1">
              <div className="fetures-icon-block">
                <img src={Icon3} alt="img" />
              </div>
              <h3>Control Documentation</h3>
              <p>
              Ensure everyone in your organisation is using the same tools.
              </p>
            </div>
          </Col>
          <Col xs={12} sm={6} md={4}>
            <div className="fetures-block v2">
              <div className="fetures-icon-block">
                <img src={Icon4} alt="img" />
              </div>
              <h3>Centralise Change Management</h3>
              <p>
                No more storing change & requirement documents in a drawer under your desk.
              </p>
            </div>
          </Col>
          <Col xs={12} sm={6} md={4}>
            <div className="fetures-block v1">
              <div className="fetures-icon-block">
                <img src={Icon5} alt="img" />
              </div>
              <h3>Embrace Best Practice</h3>
              <p>
                Built on best practice industry principals, Sortd ensures you're doing the right thing at each step.
              </p>
            </div>
          </Col>
          <Col xs={12} sm={6} md={4}>
            <div className="fetures-block v2">
              <div className="fetures-icon-block">
                <img src={Icon6} alt="img" />
              </div>
              <h3>Save Time</h3>
              <p>
                Spend less time planning and more time changing.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </FeturesSectionWrapper>
  );
};

export default Features;
