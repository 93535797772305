import React from "react";

import { Container, Row, Col } from "reusecore/Layout";
import Button from "reusecore/Button";
import VintageBox from "reusecore/VintageBox";
import { FaRegUserCircle } from "react-icons/fa";

import CTASectionWrapper from "./CTASection.style";

import image1 from "assets/images/hosting/CTA/01.png";
import Particle1 from "assets/images/hosting/vintage/01.png";

const CTAHosting = () => {
  return (
    <CTASectionWrapper>
      <Container>
        <Row Vcenter={true}>
          <Col xs={12} sm={6} lg={5}>
            <div className="CTA-content">
              <h2>
                Requirements. <br/>
                Done Right.
              </h2>
              <p>
                Requirements Pro use the latest real-time technology enabling blazing fast requirements gathering and prioritisation sessions. 
                Data is used throughout the platform to uncover hidden value and insights every step of the way.
                Resolve team disagreements with the click of a button.
              </p>
              <VintageBox
              right={true}
              vintageTwo={true}
              position="relative"
              >
              <Button className="banner-btn one" onClick={() => window.open('https://requirements.sortd.io', '_blank')}>
                <FaRegUserCircle className="icon-left" />
                Start Free Trial
              </Button>
              </VintageBox>
            </div>
          </Col>
          <Col xs={12} sm={6} lg={7}>
            <div className="CTA-img">
              <img src={image1} alt="img" />
            </div>
          </Col>
        </Row>
      </Container>
    </CTASectionWrapper>
  );
};

export default CTAHosting;
