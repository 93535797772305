import React from "react";
import { Container, Row, Col } from "reusecore/Layout";
import SectionTitle from "reusecore/SectionTitle";
import Button from "reusecore/Button";
import Img from "gatsby-image"
import { StaticQuery, graphql, navigate } from "gatsby"
import 'html5-device-mockups';
import { Carousel } from 'antd';
import { FaRegUserCircle } from "react-icons/fa";
import VintageBox from "reusecore/VintageBox";

import { IoIosArrowRoundBack, IoIosArrowRoundForward } from "react-icons/io";

import AppScreenSctionWrapper from "./appScreenSection.style";

const AppScreensClassic = () => {
  const ArrowLeft = ({ currentSlide, slideCount, ...props }) => (
    <button {...props} className="slick-arrow slick-prev-icon">
      <IoIosArrowRoundBack />
    </button>
  );
  const ArrowRight = ({ currentSlide, slideCount, ...props }) => (
    <button {...props} className="slick-arrow slick-next-icon">
      <IoIosArrowRoundForward />
    </button>
  );
  
  return (
    <AppScreenSctionWrapper>
      <Container>
        <Row>
          <Col xs={12} sm={6}>
            <SectionTitle
              className="section-title-block"
              leftAlign={true}
              UniWidth="100%"
              mb={50}
            >
              <h4>The Platform</h4>
              <h2>
                Get a <span>sneak peek </span> of the platform.
              </h2>
            </SectionTitle>
          </Col>
          <Col xs={12} sm={6}>
            <div className="view-more-button">
            <VintageBox
              right={true}
              vintageTwo={true}
              position="relative"
              >
              <Button className="banner-btn one" onClick={() => window.open('https://requirements.sortd.io', '_blank')}>
                <FaRegUserCircle className="icon-left" />
                Start Free Trial
              </Button>
              </VintageBox>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
          <div className="device-wrapper" style={{ maxWidth: '800px', margin: '0 auto' }}>
            <div
              className="device"
              data-device="MacbookPro"
              data-orientation="portrait"
              data-color="black"
            >
            <StaticQuery
              query={graphql`
              query {
                readiness: file(relativePath: { eq: "host.png" }) {
                  childImageSharp {
                    # Specify the image processing specifications right in the query.
                    # Makes it trivial to update as your page's design changes.
                    fluid(maxWidth: 1024) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
                snapshot: file(relativePath: { eq: "answer.png" }) {
                  childImageSharp {
                    # Specify the image processing specifications right in the query.
                    # Makes it trivial to update as your page's design changes.
                    fluid(maxWidth: 1024) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }

              }`}
              render={data => (
              <div className="screen" >
                <Carousel dots={false} autoplay>
                  <div>
                    <Img fluid={data.snapshot.childImageSharp.fluid} />
                  </div>
                  <div>
                    <Img fluid={data.readiness.childImageSharp.fluid} />
                  </div>
                </Carousel>
              </div>
            )} />
            </div>
          </div>
          </Col>
        </Row>
      </Container>
    </AppScreenSctionWrapper>
  );
};

export default AppScreensClassic;
